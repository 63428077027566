import { useTranslation } from '@wix/yoshi-flow-editor';
import { HEADER_TITLE_ID } from '../../Widget/Header/constants';
import { Optional } from '../../../../types/types';
import { AccessibilityAttributes } from '../../../../utils/accessibility/constants';
import { usePreset } from '../usePreset';

export type UseWidgetAccessibilityAttributes =
  typeof useWidgetAccessibilityAttributes;

export const useWidgetAccessibilityAttributes = (
  title?: string,
): Optional<accessibilityattributes> => {
  const { t } = useTranslation();
  const { isCalendarPage } = usePreset();

  if (!isCalendarPage) {
    return {
      role: 'region',
      ...(title
        ? { 'aria-labelledby': HEADER_TITLE_ID }
        : { 'aria-label': t('app.settings.defaults.header-title') }),
    };
  }
};
</accessibilityattributes>