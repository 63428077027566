import React, { useMemo } from 'react';
import { DatePickerDateIndicationProps as dateIndicationProps } from 'wix-ui-tpa/cssVars';
import DotIcon from '../../../../../assets/Icons/DotIndication.svg';
import { getLocalDateTimeStartOfDay } from '../../../../../utils/dateAndTime/dateAndTime';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import { rfcToShiftedDate } from '@wix/bookings-date-time';
import { TriggeredByOptions } from '../../../../../types/types';
import {
  DateAvailabilityStatus,
  DatePickerViewModel,
} from '../../../ViewModel/datePickerViewModel/datePickerViewModel';
import { DatePickerDataHooks } from './constants';
import { classes, st } from './DatePicker.st.css';

const DotIndication: React.FC<{
  dateAvailabilityStatus?: DateAvailabilityStatus;
  shouldShow: boolean;
  isSelected: boolean;
}> = (
  { dateAvailabilityStatus, shouldShow, isSelected } = {
    dateAvailabilityStatus: undefined,
    shouldShow: false,
    isSelected: false,
  },
) => {
  const hasAvailableSlots =
    dateAvailabilityStatus === DateAvailabilityStatus.HAS_AVAILABLE_SLOTS;
  return (
    <div className="{st(classes.dateIndicationContainer," {="" visible:="" shouldShow,="" selected:="" isSelected,="" })}="" data-available="{hasAvailableSlots}">
      <doticon data-hook="{DatePickerDataHooks.DOT_ICON}" className="{" hasAvailableSlots="" ?="" classes.availableDateIndication="" :="" classes.notAvailableDateIndication="" }=""></doticon>
    </div>
  );
};

export const useDatePicker = (datePickerViewModel: DatePickerViewModel) => {
  const { onDateSelected } = useCalendarActions();
  const {
    selectedDate: localDateTime,
    dateAvailabilityStatuses,
    firstDayOfTheWeek,
    todayLocalDateTime,
    locale,
    accessibility,
  } = datePickerViewModel;
  const onDateChange = async (date: Date) => {
    const localDateTimeStartOfDay = getLocalDateTimeStartOfDay(date);
    await onDateSelected(
      localDateTimeStartOfDay,
      TriggeredByOptions.DATE_SELECTED,
    );
  };

  const selectedDate = useMemo(
    () => rfcToShiftedDate(localDateTime),
    [localDateTime],
  );
  const today = useMemo(
    () => rfcToShiftedDate(todayLocalDateTime),
    [todayLocalDateTime],
  );

  const dateIndications = ({ date, isSelected }: dateIndicationProps) => {
    const dateAvailabilityStatus = dateAvailabilityStatuses.get(
      getLocalDateTimeStartOfDay(date),
    );
    return dateAvailabilityStatus ? (
      <dotindication isSelected="{isSelected}" dateAvailabilityStatus="{dateAvailabilityStatus}" shouldShow="{!!dateAvailabilityStatus}"></dotindication>
    ) : null;
  };

  return {
    onDateChange,
    dateIndications,
    selectedDate,
    today,
    accessibility,
    firstDayOfTheWeek,
    locale,
  };
};
