import React, { KeyboardEvent, useEffect } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { getWeekDatesRange } from 'wix-ui-tpa/date-utils';
import { SlotsStatus } from '../../../../../types/types';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import {
  getLocalDateTimeEndOfDay,
  getLocalDateTimeStartOfDay,
} from '../../../../../utils/dateAndTime/dateAndTime';
import { FirstDayOfWeek } from '../../../../../utils/dateAndTime/weekStart';

const getDayIndex = (e: KeyboardEvent) =>
  parseInt((e.target as HTMLElement).getAttribute('data-dayindex')!, 10);
const isFirstDay = (e: KeyboardEvent) => getDayIndex(e) === 0;
const isLastDay = (e: KeyboardEvent) => getDayIndex(e) === 6;

const isFromButton = (e: KeyboardEvent) =>
  (e.target as HTMLElement).tagName === 'BUTTON';

const getDayEnabledButtons = (e: KeyboardEvent): HTMLButtonElement[] =>
  Array.from(
    (e.target as HTMLElement)
      .closest('[data-dayindex]')
      ?.querySelectorAll('button:not([disabled])') || [],
  );

export function useDayKeyboardNavigation({
  slotsStatus,
  currentRange,
  firstDayOfTheWeek,
}: {
  slotsStatus: SlotsStatus;
  currentRange: {
    startOfWeek: Date;
    endOfWeek: Date;
  };
  firstDayOfTheWeek: FirstDayOfWeek;
}) {
  const { isRTL } = useEnvironment();
  const rootRef = React.useRef<htmldivelement>(null);
  const { onRangeSet } = useCalendarActions();
  const [dayToSelect, setDayToSelect] = React.useState<0 | 6>();
  const [loadingStarted, setLoadingStarted] = React.useState(false);

  useEffect(() => {
    if (dayToSelect === undefined) {
      return;
    }

    if (slotsStatus === SlotsStatus.LOADING) {
      setLoadingStarted(true);
    } else if (loadingStarted) {
      focusDay(dayToSelect);
      setDayToSelect(undefined);
      setLoadingStarted(false);
    }
  }, [slotsStatus, loadingStarted, dayToSelect]);

  function focusDay(dayIndex: number) {
    rootRef.current
      ?.querySelector<htmldivelement>(`[data-dayindex="${dayIndex}"]`)
      ?.focus();
  }

  const getLocalRange = (range: ReturnType<typeof getWeekDatesRange="">) => ({
    fromAsLocalDateTime: getLocalDateTimeStartOfDay(range.startOfWeek),
    toAsLocalDateTime: getLocalDateTimeEndOfDay(range.endOfWeek),
  });

  function goToNextWeek() {
    const nextDate = new Date(currentRange.endOfWeek);
    nextDate.setDate(nextDate.getDate() + 1);
    const nextRange = getWeekDatesRange(nextDate, firstDayOfTheWeek);

    onRangeSet(getLocalRange(nextRange));
    setDayToSelect(0);
  }

  function goToPrevWeek() {
    const prevDate = new Date(currentRange.startOfWeek);
    prevDate.setDate(prevDate.getDate() - 1);
    const nextRange = getWeekDatesRange(prevDate, firstDayOfTheWeek);

    onRangeSet(getLocalRange(nextRange));
    setDayToSelect(6);
  }

  function onKeyDown(e: KeyboardEvent) {
    switch (e.key) {
      case 'ArrowLeft':
        if (isRTL) {
          if (isLastDay(e)) {
            goToNextWeek();
          } else {
            focusDay(getDayIndex(e) + 1);
          }
        } else {
          if (isFirstDay(e)) {
            goToPrevWeek();
          } else {
            focusDay(getDayIndex(e) - 1);
          }
        }
        break;

      case 'ArrowRight':
        if (isRTL) {
          if (isFirstDay(e)) {
            goToPrevWeek();
          } else {
            focusDay(getDayIndex(e) - 1);
          }
        } else {
          if (isLastDay(e)) {
            goToNextWeek();
          } else {
            focusDay(getDayIndex(e) + 1);
          }
        }
        break;

      case 'ArrowUp':
        if (isFromButton(e)) {
          const buttons = getDayEnabledButtons(e);
          const index = buttons.indexOf(e.target as HTMLButtonElement);
          if (index > 0) {
            e.preventDefault();
            buttons[index - 1]?.focus();
          }
        }
        break;

      case 'ArrowDown':
        if (isFromButton(e)) {
          const buttons = getDayEnabledButtons(e);
          const index = buttons.indexOf(e.target as HTMLButtonElement);
          if (index >= 0 && index < buttons.length - 1) {
            e.preventDefault();
            buttons[index + 1]?.focus();
          }
        }
        break;

      case 'Home':
        if (isFromButton(e)) {
          const buttons = getDayEnabledButtons(e);
          if (buttons.length > 0) {
            e.preventDefault();
            buttons[0].focus();
          }
        }
        break;

      case 'End':
        if (isFromButton(e)) {
          const buttons = getDayEnabledButtons(e);
          if (buttons.length > 0) {
            e.preventDefault();
            buttons[buttons.length - 1].focus();
          }
        }
        break;
    }
  }

  return {
    rootRef,
    onKeyDown,
  };
}
</typeof></htmldivelement></htmldivelement>