import React from 'react';
import { CalendarActions } from '../Actions/actions';

export const CalendarActionsContext = React.createContext<calendaractions>(
  {} as CalendarActions,
);

export const CalendarActionsConsumer = CalendarActionsContext.Consumer;
export const CalendarActionsProvider = CalendarActionsContext.Provider;

export const useCalendarActions = () =>
  React.useContext(CalendarActionsContext);
</calendaractions>